







































































































































import { computed, defineComponent, onMounted, provide, Ref, ref, toRef, watch } from '@vue/composition-api'
import { useState, useActions } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import router from '@/router'
import utils from '@/shared/mixins/utils'
import generalData from '@/assets/data/general-data.json'
import variables from '@/shared/variables'
import { TranslateResult } from 'vue-i18n'
import AssetControlAssessment from '@/components/asset-control assessments/AssetControlAssessment.vue'
import assetsQueries from '@/shared/queries/assetsQueries'
import assetsData from '@/assets/data/assets-data.json'
import { Status, UserRole } from '@/shared/enum/general-enum'

export default defineComponent({
    name: 'AssetControlAssessments',
    components: { Table, AssetControlAssessment },
    props: {
        loggedInUser: {
            type: String,
        },
        validationMode: {
            default: false,
        }
    },

    setup(props) {
        const assetAssessments = ref([])
        const assessmentId = ref(null)
        const lastRecordNo = ref(null)
        const assetAssessmentRecordNo = ref(null)
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const { role } = useState(['role'])
        const noDataMessage: Ref<TranslateResult> = ref('')
        const displayAssetControlAssessment = ref(false)
        const tableCmp = ref(null)
        const { menuOpen } = useState(['menuOpen'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const totalPages = ref(0)
        const assetControlAssessDefaultFilters = ref({})
        const statusFilters: Ref = ref([])
        const infomationOp = ref(null)
        const firstRecordNo = ref(null)

        // Make validation mode available to children in sidebar
        provide('VIEW_VALIDATION_MODE', toRef(props, 'validationMode'))
        const status = computed(
            () => props.validationMode ? [Status.submitted, Status.approved, Status.declined] : []
        )

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        const calculateEffective = (assetAssessment): string => {
            if (assetAssessment.overallConclusion === null) {
                return '-'
            }
            return assetAssessment.overallConclusion && assetAssessment.overallConclusion
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, state)

            return t(stateVal, language.value)
        }

        const setDefaultFilters = () => {
            assetControlAssessDefaultFilters.value = {}
            if (router.app.$route.query.status)
                assetControlAssessDefaultFilters.value['status'] = {
                    data: statusFilters.value,
                    type: 'array',
                }
        }

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_TABLE_COLUMN_ASSET_NAME', language.value),
                    sort: true,
                    fieldName: 'assetName',
                    style: 'width: 210px;',
                },
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_CONTROL_NUMBER', language.value),
                    naturalCompare: true,
                    fieldName: 'controlNumber',
                },
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_CONTROL_TOPIC', language.value),
                    sort: true,
                    fieldName: 'topic',
                },
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_TABLE_COLUMN_EXECUTED_BY', language.value),
                    sort: true,
                    fieldName: 'fexecutedBy',
                },
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_TABLE_COLUMN_EFFECTIVE', language.value),
                    sort: true,
                    fieldName: 'effective',
                    colorBoolDot: true,
                },
                {
                    header: t('ASSET_CONTROL_ASSESSMENTS_TABLE_COLUMN_STATUS', language.value),
                    sort: true,
                    fieldName: 'status',
                },
            ]
        }
        // updating assetcontrolAssessments array with additional fields and setting description content
        const toTableFormat = (tempResultData) => {
            assetAssessments.value = []
            tempResultData.map((assetAssessment) => {
                let acAssessment = {
                    id: assetAssessment.id,
                    no: null,
                    controlNumber: '',
                    topic: '',
                    fexecutedBy: '',
                    effective: '',
                    status: null,
                    color: '',
                    info: '',
                    infoKey: '',
                    alertColorName: '',
                    assetName: ''
                }
                // creating a copy of object to hold formatted and extra attributes
                acAssessment = Object.create(assetAssessment)
                acAssessment.no = utils.padLeft('000', assetAssessment.refId)
                acAssessment.assetName = assetAssessment.asset && assetAssessment.asset.name
                acAssessment.fexecutedBy =
                    assetAssessment.executedBy &&
                    assetAssessment.executedBy.firstName + ' ' + assetAssessment.executedBy.lastName
                acAssessment.effective = calculateEffective(assetAssessment)
                acAssessment.status = convertIdToNames(assetAssessment.state)
                acAssessment.controlNumber =
                    assetAssessment.control && assetAssessment.control.controlNumber
                acAssessment.topic = assetAssessment.control && assetAssessment.control.topic

                const alertsMenuType: string = props.validationMode ? 'menu-validation' : 'not-validation-mode'
                acAssessment.color = utils.getAlertInfo(alertsMenuType, assetAssessment).color
                acAssessment.info = utils.getAlertInfo(alertsMenuType, assetAssessment).info
                acAssessment.infoKey = utils.getAlertInfo(alertsMenuType, assetAssessment).infoKey
                // get the name of alert color code in order to use in filter
                acAssessment.alertColorName = utils.getAlertInfo(
                    alertsMenuType,
                    assetAssessment
                ).alertColorName

                assetAssessments.value.push(acAssessment)
            })
        }

        const getAssetControlAssessments = async (params) => {
            loading.value = true
            const pageNum = params.pageNum
            const query = `
                query{
                    assetControlAssessments(perPage: ${variables.LIST_ITEMS_PER_PAGE}, pageNum: ${pageNum}, filter: {State: [${status.value}]}){
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items{
                            ${assetsQueries.ASSET_CONTROL_ASSESSMENTS_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(query)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving asset control assessments')
            }
            if (result) {
                const assetAssessmentsResult = result.data.assetControlAssessments.items
                assetAssessmentsResult.sort((a, b) => (a.refId > b.refId ? 1 : -1))
                totalPages.value = result.data.assetControlAssessments.pageInfo.totalPages
                filteredRecordCount.value = result.data.assetControlAssessments.pageInfo.totalCount

                toTableFormat(assetAssessmentsResult)
            }
        }
        // go to asset-assessment record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `assetAssessments` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the record id (of previous/ next)
                assessmentId.value = assetAssessments.value.find(
                    (assessment) => assessment.no === record.no
                ).id
                assetAssessmentRecordNo.value = record.no
            }
        }

        // update list after assessment is updated
        const updateListPostAssessment = async () => {
            await getAssetControlAssessments({ pageNum: 1 })
        }

        // asset assessment details slideout
        const openControlAssessmentPopup = async (id) => {
            displayAssetControlAssessment.value = true
            assessmentId.value = id
            assetAssessmentRecordNo.value = assetAssessments.value.find(
                (assessment) => assessment.id == id
            ).no
            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        // set no data message based on logged in user roles
        const setNoDataMessageForRoles = () => {
            let message
            switch (role.value) {
                case UserRole.EMPLOYEE:
                    message = 'NO_CONTROL_ASSESSMENTS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                case UserRole.VALIDATOR:
                    message =
                        'NO_VALIDATION_CONTROL_ASSESSMENTS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                default:
                    'No role found'
                    break
            }
            noDataMessage.value = t(message, language.value)
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close assessment sidebar if it's open
                    if (displayAssetControlAssessment.value) {
                        displayAssetControlAssessment.value = false
                    }
                }
            })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, () => {
            setTableHeaders()
            toTableFormat(assetAssessments.value)
            setNoDataMessageForRoles()
        })

        // statefilter prop is set by the router. Watch for changes of only the query/params change
        // and update by hand since the component won't be reloaded

        // watch for loggedInUser router prop which is coming from tiles page
        watch(
            () => [props.loggedInUser],
            async () => {
                toTableFormat(assetAssessments.value)
            }
        )
        watch(
            () => router.app.$route.query,
            async () => {
                await getAssetControlAssessments({ pageNum: 1 })
                setDefaultFilters()
            },
            {
                immediate: true,
            }
        )
        onMounted(async () => {
            closeSidebarEsc()
            setTableHeaders()
            setNoDataMessageForRoles()
        })

        return {
            assetAssessments,
            assessmentId,
            lastRecordNo,
            assetAssessmentRecordNo,
            t,
            language,
            utils,
            tableHeaders,
            getAssetControlAssessments,
            loading,
            role,
            variables,
            noDataMessage,
            openControlAssessmentPopup,
            displayAssetControlAssessment,
            tableCmp,
            goToRecord,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            assetsData,
            totalPages,
            assetControlAssessDefaultFilters,
            updateListPostAssessment,
            infomationOp,
            toggleInformation,
            firstRecordNo,
        }
    },
})
