import { Organisation } from '@/dto/types'
import { UserRole } from '../enum/general-enum'
import utils from '../mixins/utils'
import variables from '../variables'

export const hasUserRole = (organisations: Array<Organisation>, roleToCheck: UserRole) => {
    const roles =
        organisations.find(
            (organisation) =>
                organisation.id === utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION)
        )?.roles || []

    if (!roles || !roles.length) return false
    return roles.some((role) => role.name === roleToCheck)
}
