
const ASSETS_LIST = `
    id
    refId
    assetType
    alertColor{
        color
        info
        infoKey
    }
    name
    location
    keyAsset
    unit
    owner{
        username
        firstName
        lastName
        id
    }
    pii
    piiSensitive
    lastModified
    description
    assessments{
        id
        dateNextAssessment
    }
`

const ASSET_DETAILS = `
    id
    refId
    alertColor{
        color
        info
        infoKey
    }
    assetType
    description
    name
    lastModified
    location
    unit
    owner{
        username
        firstName
        lastName
        id
    }
    keyAsset
    version
    status
    ciType
    availability
    integrity
    confidentiality
    pii
    piiSensitive
    piiSensitiveExplanation
    functionalOwnership
    technicalOwnership
    supplier
    linkToContract
    contractEndDate
    assetExplanation
    controls{
        id
        topic
        controlNumber
        originNorm{
            name
        }
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
        assetControlAssessments{
            id
            asset {
                id
            }
        }
    }
    active
    assessments{
        id
        dateNextAssessment    
    }
`

const CONTROLS_LIST = `
    id
    refId
    topic
    controlNumber
    originNorm{
        name
    }
`

const CONNECTED_CONTROLS_LIST = `
    id
    topic
    controlNumber
    assetControlAssessments{
        id
        asset {
            id
        }
    }
    originNorm{
        name
    }
`

const ASSET_CONTROL_ASSESSMENT = `
    id
    order
    refId
    alertColor{
        color
        info
        infoKey
    }
    validationAlertColor{
        color
        info
        infoKey
    }
    state
    opStartDate
    opEndDate
    design
    designSetDate
    designRemark
    existence
    existenceSetDate
    existenceRemark
    operationalEffectiveness
    operationalEffectivenessRemark
    overallConclusion
    overallConclusionRemark
    maturity
    maturityAmbition
    remainingRisk
    remainingRiskAccepted
    remainingRiskAcceptedRemark
    documentationUrl
    dateNextAssessment
    assessmentExplanation
    assessmentIntervalValue
    assessmentIntervalUnit
    executedBy{
        username
        firstName
        lastName
        id
    }
    executionDate
    validationBy{
        id
        username
        firstName
        lastName
    }
    validationDate
    validationRemark
    asset {
        id
        refId
        order
        name
    }
    control{
        id
        topic
        controlNumber
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
    }
    
`

const ASSET_ASSESSMENT_APPROVAL_DETAILS = `
    id
    state
    validationBy{
        id
        username
        firstName
        lastName
    }
    validationDate
    validationRemark
`

//  control assessments list
const ASSET_CONTROL_ASSESSMENTS_LIST = `
        id
        refId
        order
        alertColor{
            color
            info
            infoKey
        }
        validationAlertColor{
            color
            info
            infoKey
        }
        asset {
            id
            description
            order
            name
        }
        control{
            id
            controlNumber
            topic
        }
        executedBy {
            username
            lastName
            firstName
        }
        dateNextAssessment
        overallConclusion
        state
    `

const CONTROL_DETAILS = `
        id
        topic
        controlNumber
        originNorm{
            name
        }
        assessmentIntervalUnit
        assessmentIntervalValue
        executionIntervalValue
        executionIntervalUnit
        `
const ASSET_INFO = `
    id
    refId
    order
    name
    `

const CONTROL_QUERY = `
    query {
        controls{
            items{
                ${CONTROLS_LIST}
            }
        }
    }
`



export default {
    ASSETS_LIST,
    ASSET_DETAILS,
    CONTROLS_LIST,
    CONNECTED_CONTROLS_LIST,
    ASSET_CONTROL_ASSESSMENT,
    ASSET_ASSESSMENT_APPROVAL_DETAILS,
    ASSET_CONTROL_ASSESSMENTS_LIST,
    CONTROL_DETAILS,
    ASSET_INFO,
    CONTROL_QUERY,
}
