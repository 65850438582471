














































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { Ref, computed, defineComponent, inject, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import AssetControlAssessmentSection from '@/shared/components/assessments/AssessmentSection.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import AsssetControlAssessmentApproval from '@/shared/components/assessments/AssessmentApproval.vue'
import utils from '@/shared/mixins/utils'
import { Status, UserRole } from '@/shared/enum/general-enum'
import generalData from '@/assets/data/general-data.json'
import variables from '@/shared/variables'
import Confirmation from '@/shared/components/Confirmation.vue'
import assetsQueries from '@/shared/queries/assetsQueries'
import { Effectiveness } from '@/shared/enum/asset-control-assessment-enum'
import { Route } from 'vue-router'

const fetchACAssessment = async (id: number) => {
    const getAssetDetailQuery = `
        query{
            assetControlAssessment(id:${id}){      
                ${assetsQueries.ASSET_CONTROL_ASSESSMENT}         
            }
        }
    `
    return apolloClient.getGraphqlData(getAssetDetailQuery)
        .then(
            (response) => response.data.assetControlAssessment
        )
}


export default defineComponent({
    components: {
        AssetControlAssessmentSection,
        AsssetControlAssessmentApproval,
        Confirmation,
    },
    props: {
        controlId: {
            type: Number,
        },
        assetId: {
            type: Number,
        },
        assessmentId: {
            type: Number,
        },
        // Make previous,next buttons available if the component is accessed via a list
        accessedFrom: {
            type: String,
        },
        accessType: {
            type: String,
        },
        //TODO: #803
        lastRecordNo: {},
        //TODO: #803
        recordNo: {},
        controlList: {
            type: Array,
        },
        controlAssessmentId: {
            type: Number,
        },
        //TODO: #803
        firstRecordNo: {},
        newAssessmentSourceId: {
            type: Number
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const AssetControlAssessmentSection = ref(null)
        // eslint-disable-next-line
        const controlDetails: any = ref(null)
        // eslint-disable-next-line
        const assetControlAssessment: any = ref(null)
        // Souce for creating new assessment, based on newAssessmentSourceId prop
        const newACAssessmentSource = ref(null)
        const editable = ref(false)
        const toast = useToast()
        const enableApproval = ref(false)
        const assetControlAssessmentApprovalSection = ref(null)
        const approvalContent = ref(null)
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const displayApproveConfirmation = ref(false)
        const displaydeclinedConfirmation = ref(false)
        const displaySubmitConfirmation = ref(false)
        const nameInitals = ref('')
        const disable = ref(false)
        const originComponents = ref({
            controlList: 'Controls',
            assetAssessments: 'AssetControlAssessments',
            assets: 'Assets',
        })
        const routedFrom = ref('')
        const assetDetails = ref(null)
        const loading = ref(false)
        const orderNo = ref(null)
        const assetControlAssessmentInfo = ref('')
        const displayDeleteConfirmation = ref(false)
        const VIEW_VALIDATION_MODE: Ref<boolean> = inject('VIEW_VALIDATION_MODE', ref(false))

        const goBackToControlList = () => {
            router.back()
        }

        // button handle logically
        const buttonEnable = () => {
            if (assetControlAssessment.value) {
                editable.value = false
            } else {
                editable.value = true
            }
        }

        // get info box text based on navigated menu option
        const getControlAssessmentInfoBoxText = (route: Route) => {
            assetControlAssessmentInfo.value = utils.getInfoTextTranslationKeyFor(
                'asset-control-assessment',
                route.name
            )

        }

        // go to record (previous/ next)
        const goToRecord = (type: string) => {
            emit('go-to-record', { no: props.recordNo, type })
        }

        // set assessment configurations based on response data
        const setAssessmentConfiguration = () => {
            // disable approval section to avoid approving their own action plan
            const username =
                assetControlAssessment.value.executedBy &&
                assetControlAssessment.value.executedBy.username
            disable.value = utils.disableApprovalSection(username)

            // set name with initials to display in avatar
            const fullNameOwner =
                (assetControlAssessment.value.executedBy &&
                    assetControlAssessment.value.executedBy.firstName) +
                ' ' +
                (assetControlAssessment.value.executedBy &&
                    assetControlAssessment.value.executedBy.lastName)
            nameInitals.value = utils.getNameInitials(fullNameOwner)

            const alertsMenuType: string = VIEW_VALIDATION_MODE.value ? 'menu-validation' : 'not-validation-mode'
            assetControlAssessment.value.color = utils.getAlertInfo(
                alertsMenuType,
                assetControlAssessment.value
            ).color
            assetControlAssessment.value.info = utils.getAlertInfo(
                alertsMenuType,
                assetControlAssessment.value
            ).info
            assetControlAssessment.value.infoKey = utils.getAlertInfo(
                alertsMenuType,
                assetControlAssessment.value
            ).infoKey
        }

        // get asset control assessments from assessment id
        const getAssetControlAssessmentDetails = async () => {
            assetControlAssessment.value = null
            approvalContent.value = null
            controlDetails.value = null
            assetDetails.value = null
            enableApproval.value = false
            loading.value = true
            fetchACAssessment(props.assessmentId)
                .then(
                    (ACAssessmentResult) => {
                        assetControlAssessment.value = ACAssessmentResult
                        // set order no
                        orderNo.value = utils.padLeft('000', ACAssessmentResult.order)

                        // set control details
                        controlDetails.value = ACAssessmentResult.control
                        // set asset details
                        assetDetails.value = ACAssessmentResult.asset
                        assetDetails.value.no = utils.padLeft('000', assetDetails.value.order) // creating no

                        buttonEnable()

                        if (assetControlAssessment.value) {
                            setAssessmentConfiguration()
                        }
                        // check for approval content available
                        if (
                            assetControlAssessment.value &&
                            assetControlAssessment.value.validationBy &&
                            assetControlAssessment.value.validationBy.id
                        ) {
                            // set approval content
                            approvalContent.value = {
                                validationBy: assetControlAssessment.value.validationBy,
                                validationDate: assetControlAssessment.value.validationDate,
                                validationRemark: assetControlAssessment.value.validationRemark,
                            }
                        }

                        // empty approval content when assessment state is in submit state or in progress state
                        if (
                            assetControlAssessment.value &&
                            (assetControlAssessment.value.state === Status.submitted ||
                                assetControlAssessment.value.state === Status.inprogress)
                        ) {
                            approvalContent.value = null
                        }
                    }
                )
                .catch(
                    () => {
                        throw Error('Error while retrieving asset control assessment details')
                    }
                )
                .finally(
                    () => loading.value = false
                )
        }

        // update asset assessment state
        const updateAssetControlAssessmentState = async (status: number) => {
            const inputStatus = {
                assetControlAssessmentState: status,
            }

            const returnQuery = `assetControlAssessment{
                ${assetsQueries.ASSET_CONTROL_ASSESSMENT}
            }`
            let resultOfStatus
            try {
                resultOfStatus = await utils.updateDocumentState(
                    assetControlAssessment.value.id,
                    returnQuery,
                    inputStatus
                )
            } catch (err) {
                return 'error'
            }

            if (resultOfStatus) {
                assetControlAssessment.value = resultOfStatus.assetControlAssessment
                setAssessmentConfiguration()
                emit('post-assessment-update')
                return 'success'
            }
            return 'error'
        }

        //  save asset assessment
        const saveAssetControlAssessment = async () => {
            AssetControlAssessmentSection.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(AssetControlAssessmentSection.value.errors)) {
                return
            }

            const assetId = assetDetails.value.id
            const controlId = controlDetails.value.id
            const assetControlAssessmentForm = AssetControlAssessmentSection.value.assessmentForm
            //  if design or existence or op selected as `not tested` date fields are null
            if (assetControlAssessmentForm.DESIGN === Effectiveness.NOT_TESTED) {
                assetControlAssessmentForm.DESIGN_DATE = null
            }

            if (assetControlAssessmentForm.EXISTENCE === Effectiveness.NOT_TESTED) {
                assetControlAssessmentForm.EXISTENCE_DATE = null
            }

            if (assetControlAssessmentForm.OPERATIONAL_EFFECTIVENESS === Effectiveness.NOT_TESTED) {
                assetControlAssessmentForm.OP_START_DATE = null
                assetControlAssessmentForm.OP_END_DATE = null
            }

            const controlAssessmentData = {
                asset: assetId,
                control: controlId,
                opStartDate: assetControlAssessmentForm.OP_START_DATE,
                opEndDate: assetControlAssessmentForm.OP_END_DATE,
                executedBy: assetControlAssessmentForm.EXECUTED_BY,
                design: assetControlAssessmentForm.DESIGN,
                designSetDate: assetControlAssessmentForm.DESIGN_DATE,
                designRemark: assetControlAssessmentForm.DESIGN_REMARK,
                existence: assetControlAssessmentForm.EXISTENCE,
                existenceSetDate: assetControlAssessmentForm.EXISTENCE_DATE,
                existenceRemark: assetControlAssessmentForm.EXISTENCE_REMARK,
                operationalEffectiveness: assetControlAssessmentForm.OPERATIONAL_EFFECTIVENESS,
                operationalEffectivenessRemark:
                    assetControlAssessmentForm.OPERATIONAL_EFFECTIVENESS_REMARK,
                overallConclusion:
                    assetControlAssessmentForm.OVERALL === Effectiveness.EFFECTIVE ? true : false,
                overallConclusionRemark: assetControlAssessmentForm.OVERALL_CONCLUSION_REMARK,
                maturity: assetControlAssessmentForm.MATURITY,
                maturityAmbition: assetControlAssessmentForm.MATURITY_AMBITION,
                remainingRiskAccepted:
                    assetControlAssessmentForm.REMAINING_RISK_ACCEPTED === 1 ? true : false,
                remainingRiskAcceptedRemark:
                    assetControlAssessmentForm.REMAINING_RISK_ACCEPTED_REMARK,
                documentationUrl: assetControlAssessmentForm.DOCUMENTATION_URL,
                dateNextAssessment: assetControlAssessmentForm.DATE_NEXT_ASSESSMENT,
                executionDate: assetControlAssessmentForm.EXECUTION_DATE,
                assessmentIntervalValue: Number(
                    assetControlAssessmentForm.ASSESSMENT_INTERVAL_VALUE
                ),
                assessmentIntervalUnit: assetControlAssessmentForm.ASSESSMENT_INTERVAL_UNIT,
                assessmentExplanation: assetControlAssessmentForm.ASSESSMENT_EXPLANATION,
            }

            // mutation query
            let mutationQuery
            // create
            if (!assetControlAssessment.value) {
                mutationQuery = `mutation ($input: AssetControlAssessmentInput!) {
                createAssetControlAssessment(input: $input) {
                        assessment{
                            ${assetsQueries.ASSET_CONTROL_ASSESSMENT}
                        }
                            status
                            error
                }
              }`
            } else {
                // update
                mutationQuery = `mutation ($input: AssetControlAssessmentInput!) {
                    updateAssetControlAssessment(id:${assetControlAssessment.value.id}, input: $input) {
                            status
                            error
                }
              }`
            }

            const input = controlAssessmentData
            let result
            try {
                // update data api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('ASSET_ASSESSMENT_SAVE_ERROR_MESSAGE', language.value))
                throw Error('Error while saving asset control assessment data')
            }
            // create new
            if (
                result.data.createAssetControlAssessment &&
                result.data.createAssetControlAssessment.status
            ) {
                assetControlAssessment.value = result.data.createAssetControlAssessment.assessment

                // 2- in progress
                const status = await updateAssetControlAssessmentState(Status.inprogress)
                buttonEnable()
                if (status === 'success') {
                    toast.success(t('ASSET_ASSESSMENT_SAVE_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('ASSET_ASSESSMENT_SAVE_ERROR_MESSAGE', language.value))
                }
            } else if (
                result.data.updateAssetControlAssessment &&
                result.data.updateAssetControlAssessment.status
            ) {
                //update

                // 2- in progress
                // when resubmitting declined assessment, resetting approval section content and fields
                approvalContent.value = null
                const status = await updateAssetControlAssessmentState(Status.inprogress)
                buttonEnable()

                if (status === 'success') {
                    toast.success(t('ASSET_ASSESSMENT_SAVE_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('ASSET_ASSESSMENT_SAVE_ERROR_MESSAGE', language.value))
                }
            } else {
                toast.error(t('ASSET_ASSESSMENT_SAVE_ERROR_MESSAGE', language.value))
            }
        }

        //  on click of cancel button
        const cancel = () => {
            if (editable.value && assetControlAssessment.value) {
                // if the document is in edit mode, it will exit back to view
                editable.value = false
            } else {
                // if you are in create new form, form fields will be clear
                AssetControlAssessmentSection.value.cancelForm()
            }
            emit('close-assessment')
        }

        // cancel approval
        const cancelApproval = () => {
            assetControlAssessmentApprovalSection.value &&
                assetControlAssessmentApprovalSection.value.cancelForm()
            enableApproval.value = false
            emit('close-assessment')
        }

        // asset assessment approve content
        const submitAssetControlAssessmentApprovalContent = async () => {
            const assetControlAssessmentApprovalForm =
                assetControlAssessmentApprovalSection.value.assessmentApprovalForm

            // asset assessment approval input object
            const input = {
                validationBy: assetControlAssessmentApprovalForm.VALIDATION_BY,
                validationDate: assetControlAssessmentApprovalForm.VALIDATION_DATE,
                validationRemark: assetControlAssessmentApprovalForm.VALIDATION_REMARK,
            }

            // mutation query

            // approved
            const mutationQuery = `mutation ($input: AssetControlAssessmentApprovalInput!) {
                    approveAssetControlAssessment(id: ${assetControlAssessment.value.id}, input: $input) {
                        assessment{
                            ${assetsQueries.ASSET_ASSESSMENT_APPROVAL_DETAILS}
                        }

                        status
                        error
                    }   
                }`

            // approve data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                return 'error'
            }

            // check for success approval
            if (
                result.data.approveAssetControlAssessment &&
                result.data.approveAssetControlAssessment.status
            ) {
                const assetApproval = result.data.approveAssetControlAssessment.assessment

                // set approval content
                approvalContent.value = {
                    validationBy: assetApproval.validationBy,
                    validationDate: assetApproval.validationDate,
                    validationRemark: assetApproval.validationRemark,
                }

                return 'submitted'
            } else {
                return 'error'
            }
        }

        // on success of approval content submition update the state of assessment as approved
        const approveAssessment = async () => {
            // asset assessment

            const approval = await submitAssetControlAssessmentApprovalContent()
            if (approval === 'submitted') {
                // 4- approved
                const status = await updateAssetControlAssessmentState(Status.approved)
                if (status === 'success') {
                    toast.success(t('ASSET_ASSESSMENT_APPROVE_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('ASSET_ASSESSMENT_APPROVE_SUCCESS_MESSAGE', language.value))
                }
            } else {
                toast.error(t('ASSET_ASSESSMENT_APPROVE_SUCCESS_MESSAGE', language.value))
            }
        }

        // on success of approval content submition update the state of assessment as declined
        const declineAssessment = async () => {
            // asset assessment

            const approval = await submitAssetControlAssessmentApprovalContent()
            if (approval === 'submitted') {
                // 5- declined
                const status = await updateAssetControlAssessmentState(Status.declined)
                if (status === 'success') {
                    toast.success(t('ASSET_ASSESSMENT_DECLINE_SUCCESS_MESSAGE', language.value))
                } else {
                    toast.error(t('ASSET_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
                }
            } else {
                toast.error(t('ASSET_ASSESSMENT_DECLINE_ERROR_MESSAGE', language.value))
            }
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, Number(state))
            return t(stateVal, language.value)
        }

        // open save confirmation
        const openSaveConfirmation = () => {
            if (assetControlAssessment.value) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity

                saveAssetControlAssessment()
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            // cancel for edit assessment
            if (assetControlAssessment.value) {
                displayCancelConfirmation.value = true
            } else if (enableApproval.value) {
                // cancel for approval section
                cancelApproval()
            } else {
                // for new create entity
                displayCancelConfirmation.value = true
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false

            saveAssetControlAssessment()
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancel()
        }

        // on success submit confirm
        const successSubmitConfirmation = async () => {
            displaySubmitConfirmation.value = false
            enableApproval.value = true

            const status = await updateAssetControlAssessmentState(Status.submitted)
            if (status === 'success') {
                toast.success(t('ASSET_ASSESSMENT_SUBMIT_SUCCESS_MESSAGE', language.value))
            } else {
                toast.error(t('ASSET_ASSESSMENT_SUBMIT_ERROR_MESSAGE', language.value))
            }
        }
        // on success approve confirm
        const successApproveConfirmation = () => {
            displayApproveConfirmation.value = false
            assetControlAssessmentApprovalSection.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(assetControlAssessmentApprovalSection.value.errors)) {
                return
            }
            approveAssessment()
        }

        // on success decline confirm
        const successDeclinedConfirmation = () => {
            displaydeclinedConfirmation.value = false
            assetControlAssessmentApprovalSection.value.validateForm()

            // check for validation errors
            if (utils.hasErrors(assetControlAssessmentApprovalSection.value.errors)) {
                return
            }
            declineAssessment()
        }

        // get asset details for the asset control assessment
        const getAssetDetails = async () => {
            assetDetails.value = null
            const getAssetDetailQuery = `
                query{
                    asset (id: ${props.assetId}){
                        ${assetsQueries.ASSET_INFO}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getAssetDetailQuery)
            } catch {
                throw Error('Error while retrieving asset details')
            }

            if (result) {
                assetDetails.value = result.data.asset
                assetDetails.value.no = utils.padLeft('000', assetDetails.value.order) // creating no
            }
        }

        // get control details for the asset control assessment and cnotrol assessment
        const getControlDetails = async (controlDetailsQuery) => {
            controlDetails.value = null
            const getControlDetailQuery = `
                query{
                   control(id: ${props.controlId}){
                        ${controlDetailsQuery}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getControlDetailQuery)
            } catch {
                throw Error('Error while retrieving control details')
            }

            if (result) {
                controlDetails.value = result.data.control
            }
        }

        // get asset and control data to fill header details of the assessment
        const getAssetAndControlDetails = async (controlDetailsQuery) => {
            getAssetDetails()
            getControlDetails(controlDetailsQuery)
        }

        // enable submit button based on following conditions
        const isSubmitEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                !enableApproval.value &&
                !approvalContent.value &&
                assetControlAssessment.value &&
                (assetControlAssessment.value.state === Status.inprogress ||
                    assetControlAssessment.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        // enable approval buttons based on following conditions
        const isApprovalBtnsEnabled = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                ((enableApproval.value && !editable.value) ||
                    (assetControlAssessment.value &&
                        assetControlAssessment.value.state === Status.submitted) ||
                    enableApproval.value) &&
                roles.includes(role.value) &&
                !approvalContent.value
            )
        })

        // display approval content based on following conditions
        const displayApprovalContent = computed(() => {
            const roles: string[] = [
                UserRole.VALIDATOR,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                approvalContent.value ||
                (((enableApproval.value && !editable.value) ||
                    (assetControlAssessment.value &&
                        assetControlAssessment.value.state === Status.submitted)) &&
                    roles.includes(role.value))
            )
        })

        // display edit button based on following conditions
        const isEditEnabled = computed(() => {
            const roles: string[] = [
                UserRole.EMPLOYEE,
                UserRole.PERIUM_ADMIN,
                UserRole.DEVELOPER_ADMIN,
            ]
            return (
                !editable.value &&
                assetControlAssessment.value &&
                (assetControlAssessment.value.state === Status.inprogress ||
                    assetControlAssessment.value.state === Status.declined) &&
                roles.includes(role.value)
            )
        })

        // delete asset control assessment
        const deleteAssetControlAssessment = async () => {
            const deleteQuery = `
                    mutation{
                        deleteAssetControlAssessment(id: ${assetControlAssessment.value.id}){
                            status
                            error
                        }
                    }
                    `
            // approve data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(deleteQuery, null)
            } catch (err) {
                displayDeleteConfirmation.value = false
                toast.error(t('ASSET_CONTROL_ASSESSMENT_DELETE_FAIL_MESSAGE', language.value))
                throw Error('Error while deleting asset control assessment')
            }

            if (result.data.deleteAssetControlAssessment.status) {
                displayDeleteConfirmation.value = false
                toast.success(t('ASSET_CONTROL_ASSESSMENT_DELETE_SUCCESS_MESSAGE', language.value))
                emit('close-assessment')
                emit('get-assessments')
            } else toast.error(t('ASSET_CONTROL_ASSESSMENT_DELETE_FAIL_MESSAGE', language.value))
        }

        const deleteAssessment = () => {
            deleteAssetControlAssessment()
        }

        const reuseACAssessment = (newAssessmentSourceId: number) => {
            assetControlAssessment.value = null
            approvalContent.value = null
            controlDetails.value = null
            assetDetails.value = null
            enableApproval.value = false
            loading.value = true
            fetchACAssessment(newAssessmentSourceId).then(
                (ACAssessmentResult) => {
                    controlDetails.value = ACAssessmentResult.control
                    assetDetails.value = ACAssessmentResult.asset
                    assetDetails.value.no = utils.padLeft('000', assetDetails.value.order) 
                    newACAssessmentSource.value = ACAssessmentResult
                }
            )
            .catch(
                () => {
                    throw Error("Error while loading reused ACAssessment")
                }
            )
            .finally(
                () => loading.value = false
            )
        }

        //  watch for assessment ID  changes
        watch(
            [
                () => props.assessmentId,
                () => props.newAssessmentSourceId,
            ],
            ([assessmentId, newAssessmentSourceId]) => {
                if (assessmentId) {
                    getAssetControlAssessmentDetails()
                } else if (newAssessmentSourceId) {
                    reuseACAssessment(newAssessmentSourceId)
                } else {
                    /* since assessment is not available, get asset and control details separately using props.controlId and props.assetId.
                        When the assessment is available, can fetch asset and control details from assessment data itself. This will be only required
                        when user creates a new asset control assessment */
                    getAssetAndControlDetails(assetsQueries.CONTROL_DETAILS)
                }
            },
            { immediate: true }
        )

        //  watch route path for decide which type of component we are on.
        watch(
            () => router.app.$route,
            () => {
                routedFrom.value = router.app.$route.name
                getControlAssessmentInfoBoxText(router.app.$route)
            },
            {
                immediate: true,
            }
        )
        
        return {
            t,
            language,
            AssetControlAssessmentSection,
            toast,
            controlDetails,
            assetControlAssessment,
            editable,
            goBackToControlList,
            cancel,
            enableApproval,
            approveAssessment,
            assetControlAssessmentApprovalSection,
            approvalContent,
            cancelApproval,
            infomationOp,
            toggleInformation,
            declineAssessment,
            Status,
            convertIdToNames,
            utils,
            variables,
            role,
            disable,
            openSaveConfirmation,
            openCancelConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            displayCancelConfirmation,
            displaySaveConfirmation,
            nameInitals,
            displayApproveConfirmation,
            displaydeclinedConfirmation,
            successApproveConfirmation,
            successDeclinedConfirmation,
            displaySubmitConfirmation,
            successSubmitConfirmation,
            originComponents,
            routedFrom,
            assetDetails,
            loading,
            VIEW_VALIDATION_MODE,
            UserRole,
            goToRecord,
            orderNo,
            assetControlAssessmentInfo,
            isSubmitEnabled,
            isApprovalBtnsEnabled,
            isEditEnabled,
            displayApprovalContent,
            displayDeleteConfirmation,
            deleteAssessment,
            newACAssessmentSource,
        }
    },
})
