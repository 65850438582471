





















import { defineComponent, computed } from '@vue/composition-api'
import { useState } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import { useToast } from 'vue-toastification/composition'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    name: "DocumentationLink",
    props: {
        documentationUrl: {
            type: String
        }
    },
    setup(props) {
        const toast = useToast()
        const { t } = useI18n()
        const { language } = useState(['language'])

        const linkProps = computed(() => {
            if (utils.isInternalDocumentationURL(props.documentationUrl)) {
                return {
                    showAsLink: false,
                    showCopyButton: true,
                    text: props.documentationUrl,
                }
            }
            if (utils.isExternalDocumentationURL(props.documentationUrl)) {
                return {
                    showAsLink: true,
                    showCopyButton: false,
                    href: props.documentationUrl,
                    text: props.documentationUrl,
                }
            } else if (props.documentationUrl && utils.isExternalDocumentationURL('https://' + props.documentationUrl)) {
                // Documentation url is not empty, and we can turn the link into a valid 
                // documentation URL by adding https:// in front
                return {
                    showAsLink: true,
                    showCopyButton: false,
                    href: 'https://' + props.documentationUrl,
                    text: 'https://' + props.documentationUrl,
                }
            } else {
                // The link is not valid as external link or as internal link.
                // Just show the text without special markup.
                return {
                    showAsLink: false,
                    showCopyButton: false,
                    text: props.documentationUrl,
                }
            }
        })


        const copyUrlToClipboard = async () => {
            await navigator.clipboard.writeText(props.documentationUrl)
            toast.success(t('CLIPBOARD_MESSAGE', language))
        }

        return {
            linkProps,
            copyUrlToClipboard,
        }
    }
})
